// ==============================
// Custom style
// 自定义样式
// ==============================

img.lazyloaded {
display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}